import React from 'react';
import { useState } from "react";
import "./Header.css";

import { FaHouseDamage } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { FaList } from "react-icons/fa";
import { FaGripHorizontal } from "react-icons/fa";
import { FaAddressBook } from "react-icons/fa";
import {FaPhoneAlt} from 'react-icons/fa'
import {FaRegEnvelope } from 'react-icons/fa'

import  {Link}  from 'react-router-dom';

import img from './Images/hhh.png';

function Navi() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };

    const resetNavbar = () => {
      setActive('nav__menu');
      setIcon('nav__toggler');
   
  };
  return (
    <div className="nasv">
    <nav className="nav">
     
     <div className='logo2'>
      <img src={img} className='img1'></img>
     </div>     

<div className='nd'>

<div className={active}>
      <ul className='ul'>
      <li className="list"><Link to='/' className="list" onClick={resetNavbar}><FaHouseDamage className='icon2'/> &nbsp;HOME</Link></li>
      <li className="list"><Link to='/aboutus' className="list" onClick={resetNavbar}><FaAddressCard className='icon2'/>&nbsp;ABOUT</Link></li>
        <li className="list"><Link to='/menu' className="list" onClick={resetNavbar}><FaList className='icon2'/> &nbsp;MENU</Link></li>
        <li className="list"><Link to='/gallery' className="list" onClick={resetNavbar}><FaList className='icon2'/> &nbsp;GALLERY</Link></li>
        <li className="list"><Link to='/contactus' className="list" onClick={resetNavbar}><FaAddressBook className='icon2'/>&nbsp;CONTACT US</Link></li>
      

      </ul>
      </div>
      </div>
    

  


      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
    </div>
  );
}

export default Navi;








