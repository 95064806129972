import React from 'react'
import './Image1.css';
import rk1 from './Images/img1.jpeg';
import rk2 from './Images/img2.jpeg';
import rk3 from './Images/img3.jpeg';
import rk4 from './Images/img4.jpeg';
import rk5 from './Images/img5.jpeg';

const Image1 = () => {
  return (
    <div id='zn'>
        <div className='pz'>
            <div className='ot1'>
                <img src={rk1} className='ab1'></img>
            </div>
            <div className='ot1'>
                <img src={rk2} className='ab1'></img>
            </div>
            <div className='ot1'>
                <img src={rk3} className='ab1'></img>
            </div>
            <div className='ot1'>
                <img src={rk4} className='ab1'></img>
            </div>
      
        </div>
    </div>
  )
};

export default Image1;
