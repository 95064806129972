import React from 'react'
import Heading from '../Components/Heading';
import Image1 from '../Components/Image1';
import Image2 from '../Components/Image2';
import Image3 from '../Components/Image3';
import Image5 from '../Components/image5';
import Image4 from '../Components/Image4';
import Footer from '../Components/Footer';
import { useEffect } from 'react';

const Gallery = () => {
    useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
        <Heading></Heading>
        <Image1></Image1>
        <Image2></Image2>
      {/*  <Image3></Image3> */}
        <Image4></Image4>
        <Image5></Image5>
        <Footer></Footer>
    </div>
  )
};

export default Gallery;
