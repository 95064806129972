import React from 'react'
import './OurSpecials.css';

const OurSpecials = () => {
  return (
    <div className='bg'>
        <div className='df'>
          <br/><br/>
            <h4 className='text1'>Our Specials</h4>
        </div>
    </div>
  )
};

export default OurSpecials;
