import React from 'react'
import './Counter.css';

const Counter = () => {
  return (
    <div className='rg'>
            <div className='yy'>
                <div className='mm'>
                    <i className='fas fa-utensils'></i>
                    <p className='ff'>150+</p>
                    <h5 className='nn'>Meals</h5>
                </div>
                <div className='cc'>
                    <i className='far fa-smile'></i>
                    <p className='ii'>5000+</p>
                    <h3 className='ss'>Happy Customers</h3>
                </div>
                <div className='hh'>
                    <i className='fas fa-hotel'></i>
                    <p className='zz'>30+</p>
                     <h1 className='uu'>Our Staff</h1>
                </div>
                <div className='qq'>
                    <i className='fas fa-car'></i>
                    <p className='xx'>40+</p>
                    <h2 className='ee'>Car Parking</h2>
                </div>
            </div>

            <br/><br/>  <br/><br/>
    </div>
  )
};

export default Counter;
