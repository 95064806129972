import React from 'react'
import './Dine.css';
import D1 from './Images/d7.jpg';
import D2 from './Images/d8.jpg';

const Sheesha = () => {
  return (
    <div className='cj'>
        <div className='ha'>
                <div className='sd'>
                    <h5 className='qw'>Sheesha</h5>
                    <p className='ft'>Our hookahs are hand-crafted using only the finest tobacco and flavors. Whether you're a seasoned hookah enthusiast or a first-time user, our knowledgeable staff can help you choose the perfect flavor combination to suit your taste buds. Relax in our comfortable lounge while you indulge  in the rich, smooth smoke of our hookahs. Our  lounge offers a serene atmosphere that's perfect for a night out with friends or a date with that special someone.</p>
                           <a className='mobsms' href='tel:8999913424'><button className='slide-button3'>Book your table now</button></a>
                </div>
              <div className='r1'>
                  <div>
                      <img src={D1} className='z1'></img>
                  </div>
                  <div>
                      <img src={D2} className='z2'></img>
                </div>
              </div>
        </div>
    </div>
  )
};

export default Sheesha;
