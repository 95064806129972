import React from 'react'
import './Details.css';
import pm1 from './Images/location.png';
import pm2 from './Images/phone-call.png';
import { useRef } from 'react';
/*
import emailjs from '@emailjs/browser';*/

const Details = () => {

  /*  const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_5nlyw6e', 'template_bd7vtha', form.current, 'bsz7LIwTvX6ZYGIM3')
        .then((result) => {
  
          alert("Message Sent Successfully !!!")
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };*/

  return (
    <div className='yx'>
        <div className='yx1'> 
            <div id="ip">
                    <h1 className='cs'>Contact Us</h1>
                <div className='vm'>
                    <img src={pm1} className='qo1'></img>
                    <p className='ph1'>Club Nirvana, Viva Inn  Hotel Building, besides cafe Coffee Day Chandani Chowk, Bavdhan, Pune</p>
                    <img src={pm2} className='qo2'></img>
                    <p className='ph2'>8999913424</p>
                </div>
            </div>
       </div>
       <div className='yx2'>
           <div>
               <h2 className='ks'>Leave Us a Message</h2>
           </div>
          
     {/*           
<form  ref={form} onSubmit={sendEmail}>

*/}
       
<form className='qo5'>
<input placeholder='ENTER YOUR NAME' className='I1' name='name' id='name' type="text"></input><br />
<input placeholder='ENTER MOBILE NO' maxLength={10} className='I1' name='phn' id='phn' type="text"></input><br />
<input placeholder='ENTER YOUR EMAIL ID' className='I1' name='mail' id='mail' type="email"></input><br />
<textarea placeholder='DESCRIPTION' rows="4" className='t1' name='des' id='des'></textarea><br />
<input type="submit" value="SUBMIT"  className='slide-button3'/>
</form>
                 
       </div><br/><br/>
    </div>
  )
};

export default Details;
