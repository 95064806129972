import React from 'react'
import './Image2.css';
import yu1 from './Images/img6.jpeg';
import yu2 from './Images/img7.jpeg';
import yu3 from './Images/img8.jpeg';
import yu4 from './Images/img9.jpeg';


const Image2 = () => {
  return (
    <div id='zn'>
        <div className='pz'>
            <div className='ot1'>
                <img src={yu1} className='ab1'></img>
            </div>
            <div className='ot1'>
                <img src={yu2} className='ab1'></img>
            </div>
            <div className='ot1'>
                <img src={yu3} className='ab1'></img>
            </div>
            <div className='ot1'>
                <img src={yu4} className='ab1'></img>
            </div>
                  
        </div>
    </div>
  )
};

export default Image2;
