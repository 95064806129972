import React from 'react';
import './Dine.css';
import fg from './Images/img2.jpeg';
import D2 from './Images/img3.jpeg';

const Info = () => {
  return (
    <div className='dddd'>
    <div className='cj'>
       <div className='ha'>
        <div className='r1ddd'>
            <div>
                <img src={fg} className='z1'></img>
            </div>
                 <div>
                    <img src={D2} className='z2'></img>
                </div>
        </div>

           <div className='sd'>
                <h2 className='qw'>About Us</h2>
                <p className='ftt'>Introducing our About Us page - your one-stop spot forn everything you need to know about our exquisite restaurant and lounge! At our establishment, we pride ourselves on providing an unparalleled dining experience for guests from all walks of life. Whether you're looking to indulge in some flavorful dishes with friends and family, or to unwind after a long day with some craft cocktails - we've got your back. Our About Us page will give you an in-depth look at who we are as a restaurant and what we stand for. Learn about our culinary expertise, our commitment to using locally sourced ingredients, and the warm, inviting atmosphere we've created for our guests to enjoy. So, whether you're a longtime customer looking to learn a bit more about our establishment or a first-timer planning a visit to our restaurant, our About Us page is the perfect starting point for an unforgettable experience. Come on over and see for yourself why we're more than just another restaurant - we're a community.</p>
                 <a className='mobsms' href='tel:8999913424'><button className='slide-button3'>Book your table now</button></a>
            </div>
        </div>
    </div>
    </div>
  )
};

export default Info;
