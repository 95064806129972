import React from 'react'
import Banner from '../Components/Banner';
import MenuCard from '../Components/MenuCard';
import Footer from '../Components/Footer';
import { useEffect } from 'react';
const Menu = () => {
    useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
        <Banner></Banner>
        <MenuCard></MenuCard>
        <Footer></Footer>
    </div>
  )
};

export default Menu;
