import React from 'react'
import Info from '../Components/Info';
import Core from '../Components/Core';
import Timing from '../Components/Timing';
import Reviews from '../Components/Reviews';
import Video2 from '../Components/Video2';
import Footer from '../Components/Footer';
import { useEffect } from 'react';

const AboutUs = () => {
    useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
      <br/><br/><br/><br/>
     {/* <Video2></Video2> */}
        <Info></Info>
        <Core></Core>
        <Timing></Timing>
        <Reviews></Reviews>
        <Footer></Footer>
    </div>
  )
};

export default AboutUs;
