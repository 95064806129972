import React from 'react'
import './Dine.css';
import x1 from './Images/couple romantic dinner.jpg';
import x2 from './Images/loving romantic couple.jpg';

const CouplesFriendly = () => {
  return (
    <div className='cj'>
        <div className='ha'>
            <div className='r1'>
                <div>
                    <img src={x1} className='z1'></img>
                </div>
                <div>
                    <img src={x2} className='z2'></img>
                </div>
            </div>
            <div className='sd'>
                 <h6 className='qw'>Couple's Friendly</h6>  
                 <p className='ft'>Looking for a romantic night out with your special someone? Look no further than our couple-friendly lounge and restaurant! Our  perfect mood for your date, with soft lighting, comfortable seating, and a relaxing ambiance. Our lounge area offers the perfect space to relax and unwind after your meal. Enjoy a nightcap or two while listening to live music or simply taking in the romantic atmosphere.</p>
                       <a className='mobsms' href='tel:8999913424'><button className='slide-button3'>Book your table now</button></a>
            </div>
        </div>
    </div>
  )
};

export default CouplesFriendly;
