import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import React, { Component } from "react";
import Slider from "react-slick";
import e1 from './Images/ladies party.jpg';
import e2 from './Images/happy hour.jpg';
import e3 from './Images/lets party.jpg';
import e4 from './Images/chicken biryani.jpg';
import e5 from './Images/dj night.jpg';
import './CardSlider.css';

export default class CardSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 5000,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
              responsive: [
                {
                  breakpoint: 400,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                  }
                }]
    };
    return (
      <div className='ju'>
        <Slider {...settings}>

             <div className="hv1">
                 <div className="ua1">
                     <img src={e1} className='rr1'></img>
                 </div>
                 <div className='ww1'>
                     <h3 className='aa1'>Ladies Party</h3>
                     <p className='pp1'>Wednesday night special offer for ladies FREE 3 Shots Tequila / Vodka and Hookah</p>
                 </div>
             </div>


             <div className="hv1">
                  <div className='ua1'>
                      <img src={e2} className='rr1'></img>
                  </div>
                  <div className='ww1'>
                     <h2 className='aa1'>Happy Hours</h2>
                     <p className='pp1'>From 12pm to 6pm we offers our happy hours offer during this 10% discount on drinks and food</p>
                  </div>
             </div>

             <div className="hv1">
                  <div className='ua1'>
                      <img src={e3} className='rr1'></img>
                  </div>
                  <div className='ww1'>
                      <h1 className='aa1'>Parties</h1>
                      <p className='pp1'>we take care of all the details, so you can focus on having a  great time. From decorations to catering, we've got you covered.</p>
                  </div>
             </div>


             <div className="hv1">
                  <div className='ua1'>
                      <img src={e4} className='rr1'></img>
                  </div>
                  <div className='ww1'>
                      <h4 className='aa1'>Dine</h4>
                      <p className='pp1'>Our restaurant offers a variety of dishes that are prepared using fresh and high-quality ingredients.</p>
                  </div>
             </div>


             <div className="hv1">
                  <div className='ua1'>
                      <img src={e5} className='rr1'></img>
                  </div>
                  <div className='ww1'>
                      <h6 className='aa1'>Dj Night</h6>
                      <p className='pp1'>Come party the night away with the hottest beats and coolest vibes at Friday Saturday Sunday's DJ night!</p>
                  </div>
             </div>

        </Slider>
      </div>
    );
  }
}


