import React from 'react'
import './Video.css';
import video from "./Video/Video.mp4";

const Video = () => {
  return (
    <div className='vdd'>
        <div className="vid">
        <video autoplay="autoplay" loop="loop" muted className='v4'>
        <source src={video} type="video/mp4" />
        </video>
        </div>
    </div>
  )
};

export default Video;
