import React from 'react'
import './MenuCard.css';

const MenuCard = () => {
  return (
    <div id="gk">
        <div>
            <br/>
            <h6 className="sp1">Soups</h6>
            <br/>
            <hr className="jz1"></hr>
        </div>
        <br/>   <br/>
        <div>
            <h3 className="sp2">Veg</h3>
        </div>
<br/>
<table className="table">

<tr>
<th className="vg2">Sr No.</th>
<th className="vg2">Menu Item</th>
<th className="vg2">Price</th>
</tr>


<tr>
<td className="vg1">1.</td>
<td className="vg1">Veg Clear Soup</td>
<td className="vg1">107 /-</td>
</tr>

<tr>
<td className="vg1">2.</td>
<td className="vg1">Cream Of Tomato</td>
<td className="vg1">117 /-</td>
</tr>

<tr>
<td className="vg1">3.</td>
<td className="vg1">Lemon Coriander</td>
<td className="vg1">127 /-</td>
</tr>

<tr>
<td className="vg1">4.</td>
<td className="vg1">Veg hot and sour</td>
<td className="vg1">107 /-</td>
</tr>

<tr>
<td className="vg1">5.</td>
<td className="vg1">Veg manchaw</td>
<td className="vg1">127 /-</td>
</tr>

<tr>
<td className="vg1">6.</td>
<td className="vg1">Veg hot and sour</td>
<td className="vg1">127 /-</td>
</tr>

<tr>
<td className="vg1">7.</td>
<td className="vg1">Veg Clear Soup</td>
<td className="vg1">107 /-</td>
</tr>


<tr>    
<td className="vg1">8.</td>
<td className="vg1">Palak cream up</td>
<td className="vg1">127 /-</td>
</tr>

<tr>    
<td className="vg1">9.</td>
<td className="vg1">Zuppa de broccoli</td>
<td className="vg1">157 /-</td>
</tr>

<tr>    
<td className="vg1">10.</td>
<td className="vg1">Veg Talumein</td>
<td className="vg1">127 /-</td>
</tr>
</table>


  
<br/>
        <div>
                <div> <br/>   <br/>
                    <h2 className="sp2">Non Veg</h2>
                </div>
<br/>
<table className="table">

<tr>
<th className="vg2">Sr No.</th>
<th className="vg2">Menu Item</th>
<th className="vg2">Price</th>
</tr>


<tr>
<td className="vg1">1.</td>
<td className="vg1">Chi Malaysian Laska</td>
<td className="vg1">197 /-</td>
</tr>

<tr>
<td className="vg1">2.</td>
<td className="vg1">Chi Tom Yom</td>
<td className="vg1">157 /-</td>
</tr>

<tr>
<td className="vg1">3.</td>
<td className="vg1">Chi talumein</td>
<td className="vg1">167 /-</td>
</tr>

<tr>
<td className="vg1">4.</td>
<td className="vg1">Chi thai broth</td>
<td className="vg1">157 /-</td>
</tr>

<tr>
<td className="vg1">5.</td>
<td className="vg1">Chi oriental</td>
<td className="vg1">147 /-</td>
</tr>

<tr>
<td className="vg1">6.</td>
<td className="vg1">Chi manchaw</td>
<td className="vg1">147 /-</td>
</tr>

<tr>
<td className="vg1">7.</td>
<td className="vg1">Chi Clear</td>
<td className="vg1">127 /-</td>
</tr>


<tr>    
<td className="vg1">8.</td>
<td className="vg1">Chi Sweet Corn</td>
<td className="vg1">147 /-</td>
</tr>

<tr>    
<td className="vg1">9.</td>
<td className="vg1">Chi Hot & Sour</td>
<td className="vg1">147 /-</td>
</tr>

<tr>    
<td className="vg1">10.</td>
<td className="vg1">Chi Zuppa De broccoli</td>
<td className="vg1">177 /-</td>
</tr>

<tr>    
<td className="vg1">11.</td>
<td className="vg1">Chi Lemon Coriander</td>
<td className="vg1">147 /-</td>
</tr>

<tr>    
<td className="vg1">12.</td>
<td className="vg1">Chi Palak Soup</td>
<td className="vg1">167 /-</td>
</tr>
</table>



            </div>
            <div> <br/>   <br/>
                <h1 className="sp1">Timepass</h1>
                 <br/>
                <hr className="jz2"></hr>
            </div>
            <div>
            <div>
                <br/>
                <h4 className="sp2">Veg</h4>
                <br/>
            </div>

<table className="table">

<tr>
<th className="vg2">Sr No.</th>
<th className="vg2">Menu Item</th>
<th className="vg2">Price</th>
</tr>


<tr>
<td className="vg1">1.</td>
<td className="vg1">French Fries</td>
<td className="vg1">167 /-</td>
</tr>

<tr>
<td className="vg1">2.</td>
<td className="vg1">Desi Chakana</td>
<td className="vg1">197 /-</td>
</tr>

<tr>
<td className="vg1">3.</td>
<td className="vg1">Peri Peri Fries</td>
<td className="vg1">187 /-</td>
</tr>

<tr>
<td className="vg1">4.</td>
<td className="vg1">Cheesy Fries</td>
<td className="vg1">207 /-</td>
</tr>

<tr>
<td className="vg1">5.</td>
<td className="vg1">Paneer Kurkure</td>
<td className="vg1">157 /-</td>
</tr>

<tr>
<td className="vg1">6.</td>
<td className="vg1">Paneer Pakoda</td>
<td className="vg1">177 /-</td>
</tr>

<tr>
<td className="vg1">7.</td>
<td className="vg1">Cheese Pakoda</td>
<td className="vg1">187 /-</td>
</tr>


<tr>    
<td className="vg1">8.</td>
<td className="vg1">Masala Papad</td>
<td className="vg1">167 /-</td>
</tr>

<tr>    
<td className="vg1">9.</td>
<td className="vg1">Fry Papad</td>
<td className="vg1">177 /-</td>
</tr>

<tr>    
<td className="vg1">10.</td>
<td className="vg1">Roasted Papad</td>
<td className="vg1">27 /-</td>
</tr>

<tr>    
<td className="vg1">11.</td>
<td className="vg1">Penut Masala</td>
<td className="vg1">137 /-</td>
</tr>

<tr>    
<td className="vg1">12.</td>
<td className="vg1">Cheese Nachos</td>
<td className="vg1">207 /-</td>
</tr>

<tr>    
<td className="vg1">13.</td>
<td className="vg1">CCP</td>
<td className="vg1">187 /-</td>
</tr>

<tr>    
<td className="vg1">14.</td>
<td className="vg1">Chana Chat</td>
<td className="vg1">167 /-</td>
</tr>

<tr>    
<td className="vg1">15.</td>
<td className="vg1">Honey Chilly Potato</td>
<td className="vg1">217 /-</td>
</tr>

</table>


            <div> <br/> 
               <h5 className="sp2">Non Veg</h5>
               <br/>
            </div>
         <table className="table">

<tr>
<th className="vg2">Sr No.</th>
<th className="vg2">Menu Item</th>
<th className="vg2">Price</th>
</tr>


<tr>
<td className="vg1">1.</td>
<td className="vg1">Chi Popcorn</td>
<td className="vg1">197 /-</td>
</tr>

<tr>
<td className="vg1">2.</td>
<td className="vg1">Chi Nachos</td>
<td className="vg1">267 /-</td>
</tr>

<tr>
<td className="vg1">3.</td>
<td className="vg1">Kheema Pav</td>
<td className="vg1">307 /-</td>
</tr>

<tr>
<td className="vg1">4.</td>
<td className="vg1">Chi Koliwada</td>
<td className="vg1">277 /-</td>
</tr>

<tr>
<td className="vg1">5.</td>
<td className="vg1">Chi Roast</td>
<td className="vg1">257 /-</td>
</tr>


</table>

            </div>
            <div> <br/>   <br/>
                <h4 className="sp1">Starter</h4> <br/>
                <hr className="jz3"></hr>
            </div>
            <div> <br/>   <br/>
                <h5 className="sp2">Veg</h5>
                <br/>
            </div>
         <table className="table">

<tr>
<th className="vg2">Sr No.</th>
<th className="vg2">Menu Item</th>
<th className="vg2">Price</th>
</tr>


<tr>
<td className="vg1">1.</td>
<td className="vg1">Veg 65</td>
<td className="vg1">217 /-</td>
</tr>

<tr>
<td className="vg1">2.</td>
<td className="vg1">Paneer 65</td>
<td className="vg1">237 /-</td>
</tr>

<tr>
<td className="vg1">3.</td>
<td className="vg1">Boby Corn 65</td>
<td className="vg1">227 /-</td>
</tr>

<tr>
<td className="vg1">4.</td>
<td className="vg1">Veg Jungali</td>
<td className="vg1">257 /-</td>
</tr>

<tr>
<td className="vg1">5.</td>
<td className="vg1">Corn Chilly Dry</td>
<td className="vg1">207 /-</td>
</tr>

<tr>
<td className="vg1">6.</td>
<td className="vg1">Hara Bhara Kabab</td>
<td className="vg1">207 /-</td>
</tr>

<tr>
<td className="vg1">7.</td>
<td className="vg1">Veg Butter Fry</td>
<td className="vg1">197 /-</td>
</tr>


<tr>    
<td className="vg1">8.</td>
<td className="vg1">Corn Butter Fry</td>
<td className="vg1">187 /-</td>
</tr>

<tr>    
<td className="vg1">9.</td>
<td className="vg1">Paneer Sathe</td>
<td className="vg1">247 /-</td>
</tr>

<tr>    
<td className="vg1">10.</td>
<td className="vg1">Paneer Chilly</td>
<td className="vg1">227 /-</td>
</tr>


</table>
            <div><br/>
                <h5 className="sp2">Non Veg</h5>
            </div>
          <br/>
                   <table className="table">

<tr>
<th className="vg2">Sr No.</th>
<th className="vg2">Menu Item</th>
<th className="vg2">Price</th>
</tr>


<tr>
<td className="vg1">1.</td>
<td className="vg1">Drums of Heaven - Sauces Tosing</td>
<td className="vg1">257 /-</td>
</tr>

<tr>
<td className="vg1">2.</td>
<td className="vg1">STEAM WONTEN IN SCHEZWAN Sauce</td>
<td className="vg1">267 /-</td>
</tr>

<tr>
<td className="vg1">3.</td>
<td className="vg1">Thaichi Chiken</td>
<td className="vg1">487 /-</td>
</tr>


</table>
<br/><br/>
    </div>
  )
};

export default MenuCard;
