import React from 'react'
import Video1 from '../Components/Video1';
import Details from '../Components/Details';
import Footer from '../Components/Footer';
import { useEffect } from 'react';

const ContactUs = () => {
    useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
         <Video1></Video1>
         <Details></Details>
         <Footer></Footer>
    </div>
  )
};

export default ContactUs;
