import React from 'react'
import './Customer.css';

const Customer = () => {
  return (
    <div className='rr'>
         <h1 className='dd'>Our Happy Customers</h1>
    </div>
  )
};

export default Customer;
