import React from 'react'
import './Image4.css';
import x1 from './Images/img11.jpeg';
import x2 from './Images/img12.jpeg';
import x3 from './Images/img13.jpeg';
import x4 from './Images/img14.jpeg';


const Image4 = () => {
  return (
    <div id="zn">
        <div className="pz">
            <div className="ot1">
                <img src={x1} className="ab1"></img>
            </div>
            <div className="ot1">
                <img src={x2} className="ab1"></img>
            </div>
            <div className="ot1">
                <img src={x3} className="ab1"></img>
            </div>
            <div className='ot1'>
                <img src={x4} className="ab1"></img>
            </div>
          
        </div>
    </div>
  )
};

export default Image4;
