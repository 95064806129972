import React from 'react'
import './Video1.css';
import v1 from "./Video/Party.mp4";

const v = () => {
  return (
    <div className='vdd1'>
      <div className="vid1"><br/><br/>
        <video autoplay="autoplay" loop="loop" muted className='v41'>
        <source src={v1} type="video/mp4" />
        </video>
        </div>
    </div>
  )
};

export default v;
