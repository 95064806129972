import React from 'react'
import './Dine.css';
import p1 from './Images/img11.jpeg';
import p2 from './Images/img13.jpeg';

import D1 from './Images/d3.jpg';
import D2 from './Images/d4.jpg';

const Drinks = () => {
  return (
    <div className='cj'>
        <div className='ha'>
            <div className='r1'>
                <div>
                    <img src={D1} className='z1'></img>
                </div>
                <div>
                    <img src={D2} className='z2'></img>
                </div>
           </div>
            <div className='sd'>
                <h6 className='qw'>Drinks</h6>
                <p className='ft'>Dine parties and drinks often go together like peanut butter and jelly. When planning drinks for your dine party, consider the menu and select drinks that complement the flavours of the dishes you are serving. It's also important to offer non-alcoholic options for guests who do not drink or want to take a break from alcohol. A refreshing mocktail can be just as delicious as a cocktail and adds variety to your drink menu.</p>
                <a className='mobsms' href='tel:8999913424'><button className='slide-button3'>Book your table now</button></a>
            </div>
        </div>
    </div>
  )
};

export default Drinks;
