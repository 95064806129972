import React from 'react'
import './Heading.css';

const Heading = () => {
  return (
    <div className='lz'>
        <div>
            <h1 className='aj'>Nirvana Media Gallery</h1>
        </div>
    </div>
  )
};

export default Heading;
