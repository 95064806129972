import React from 'react'
import './Footer.css';
import bz from './Images/logo-removebg-preview.png';
import bb from './Images/heart-th.gif';
import { NavLink } from 'react-router-dom';
import { FaHouseDamage } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { FaList } from "react-icons/fa";
import { FaGripHorizontal } from "react-icons/fa";
import { FaAddressBook } from "react-icons/fa";

import {FaPhoneAlt} from 'react-icons/fa'
import {FaRegEnvelope } from 'react-icons/fa'




const Foot = () => {
/*

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_5nlyw6e', 'template_bd7vtha', form.current, 'bsz7LIwTvX6ZYGIM3')
        .then((result) => {
  
          alert("Message Sent Successfully !!!")
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };
    
    */

  return (
    <div className='footer'>

<div className='footer1'>

     <div className='column in'>
    
    <div>
<img src={bz} className="fi"/>
   </div>

   <div>

       <p className="fip"> 

    <a href="https://www.facebook.com/clubnirvanaofficial">
              <i className=" icc fab fa-facebook"></i></a>
&emsp;
        <a href="https://www.instagram.com/clubnirvanaofficial/">
              <i className=" icc fab fa-instagram"></i></a>

&emsp;
        <a href="#">
              <i className=" icc fab fa-youtube"></i></a>

</p>
   </div>


</div>   

<br />
<div className='column cd'>
    
    <div>
<p className='fh5'>EMAIL :</p>
<a className='fp2' href="mailto:info@nirvana.exads.in">info@nirvana.exads.in</a>  
    </div>

    <div>
<p className='fh5'>PHONE :</p>
<a className='fp2' href="tel:899913424">+91 899913424</a>  
    </div>

    <div>
<p className='fh5'>ADDRESS :</p>
<a className='fp22'><p className='fp2s2'>Club Nirvana, Viva Inn Hotel Building, Besides Cafe Coffee Day,Chandani Chowk,Bavdhan, Pune</p></a>  
    </div>





</div>
<br></br>


<div className='column ql'>
    <p className='fh5'>QUICK LINKS</p>
<div  className='hu'>
    <br/>
    <li><a href="/" className='apal'><FaHouseDamage className='icon2'/>&nbsp; &nbsp;HOME</a></li>
    <li><a href="/aboutus" className='apal'><FaAddressCard className='icon2'/>&nbsp;&nbsp;ABOUT US</a></li>
    <li><a href="/menu" className='apal'><FaList className='icon2'/> &nbsp;&nbsp;MENU</a></li>
    <li><a href="/gallery" className='apal'><FaList className='icon2'/>&nbsp; &nbsp;GALLERY</a></li>
    <li><a href="/contactus" className='apal'><FaAddressBook className='icon2'/>&nbsp;&nbsp;CONTACT US</a></li>
</div>
</div>

<br />
<div className='column ap'> 

<p className='fh5'>LOCATE US</p>
{/*
<div  className='hu2'>
<li><a href="/PowerPanel" className='apal'>POWER PANEL</a></li>
<li><a href="/ControlPanel" className='apal'>CONTROL PANEL</a></li>
<li><a href="/GeneratorPanels" className='apal'>GENERATOR PANEL</a></li>
<li><a href="/AutomationPanels" className='apal'>AUTOMATION PANEL</a></li>
<li><a href="/LoadBank" className='apal'>LOAD BANK</a></li>
<li><a href="/PLCPanel" className='apal'>PLC BANK</a></li>
<li><a href="/OperatorPanels" className='apal'>OPERATOR PANEL </a></li>
<li><a href="/BatteryCharger" className='apal'>BATTERY CHARGER </a></li>
<li><a href="/UPS" className='apal'>UPS </a></li>
<li><a href="/SpecialPurposeMacines" className='apal'>SPECIAL PURPOSE MACHINE </a></li>
<li><a href="/HydroTestingMacine" className='apal'>HYDRO TESTING MACHINE</a></li>
<li><a href="/BushPressingMachine" className='apal'>BUSH PRESSING MACHINE</a></li>
<li><a href="/NutRunnerMachine" className='apal'>NUT RUNNER MACHINE</a></li>
<li><a href="/AssemblyFIxtures" className='apal'>ASSEMBLY FIXTURES</a></li>

</div>

<form  >

<input placeholder='ENTER YOUR NAME' className='I1' name='name' id='name' type="text"></input><br />
<input placeholder='ENTER MOBILE NO' maxLength={10} className='I1' name='phn' id='phn' type="text"></input><br />
<input placeholder='ENTER YOUR EMAIL ID' className='I1' name='mail' id='mail' type="email"></input><br />
<textarea placeholder='DESCRIPTION' rows="4" className='t1' name='des' id='des'></textarea><br />
<input type="submit" value="SUBMIT" />
</form>
*/}
<br/>

 <div className='mapping'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d
                  3783.4632220128624!2d73.78028077501291!3d18.507957882583156!2m3
                  !1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf9f677327a3%3A0
                  x354b011ae2a60474!2sClub%20Nirvana%20Lounge%20%2CGarden%20%
                  26%20Banquet!5e0!3m2!1sen!2sin!4v1705469746855!5m2!1sen!2sin"
                   width="230" height="120" style={{ border: '0' }}></iframe>
            </div>

</div>
</div>

<div className='footer2'>
<center className='klk'>Copyright © 2024 CLUB NIRVANA| Made with <img src={bb} className="hrt"></img> by CORTICA WEB SOLUTIONS PVT LTD</center>
</div>

    </div>
  )
}

export default Foot






















/*import React from 'react'
import './Footer.css';
import bz from './Images/logo-removebg-preview.png';
import bb from './Images/heart-th.gif';
import { NavLink } from 'react-router-dom';
import { FaHouseDamage } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { FaList } from "react-icons/fa";
import { FaGripHorizontal } from "react-icons/fa";
import { FaAddressBook } from "react-icons/fa";

import {FaPhoneAlt} from 'react-icons/fa'
import {FaRegEnvelope } from 'react-icons/fa'

const Footer = () => {
  return (
    <div className='cq'>
        <div className='gv'>
            <div>
                <img src={bz} className='dh'></img>
            </div>
            <div>
              <a href="https://www.facebook.com/clubnirvanaofficial">
              <i className="fab fa-facebook"></i></a>
              <a href="">
              <i className="fab fa-instagram"></i></a>
            </div>
            <div>
                <h1 className='al1'>Quick Links</h1>
                <ul className='vertical-nav'>
                    <li className='foot-li'><NavLink className="nav-links" to="/">Home</NavLink></li>
                    <li className='foot-li'><NavLink className="nav-links" to="/aboutus">AboutUs</NavLink></li>
                    <li className='foot-li'><NavLink className="nav-links" to="/menu">Menu</NavLink></li>
                    <li className='foot-li'><NavLink className="nav-links" to="/gallery">Gallery</NavLink></li>
                    <li className='foot-li'><NavLink className="nav-links" to="contactus">ContactUs</NavLink></li>
                </ul>
            </div>
            <div>
                <h2 className='al2'>Address</h2>
                <p className='sz'>Club Nirvana, Viva Inn Hotel Building, 
                       besides cafe Coffee Day, Chandani Chowk,
                       Bavdhan, Pune</p>
            </div>

           <div>
                <h2 className='al2'>Contact</h2>
                <p className='sz2'>

                    <a href="tel:899913424" className='mobno'><FaPhoneAlt className='icon2'/>+91-899913424 </a>
                    <br/>
                    <a href="mailto:info@nirvana.exads.in" className='mobno'><FaRegEnvelope  className='icon2'/>info@nirvana.exads.in </a>

                </p>
            </div>


            <div className='mapping'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d
                  3783.4632220128624!2d73.78028077501291!3d18.507957882583156!2m3
                  !1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf9f677327a3%3A0
                  x354b011ae2a60474!2sClub%20Nirvana%20Lounge%20%2CGarden%20%
                  26%20Banquet!5e0!3m2!1sen!2sin!4v1705469746855!5m2!1sen!2sin"
                   width="280" height="162" style={{ border: '0' }}></iframe>
            </div>
        </div>
        <div className='ro'>
            <hr className='zn'></hr>
        </div>
        <div className='ln'>
               <p className='hf'>Copyright © 2024 Nirvana | Made with 
                <img src={bb} height={20} id="ax"></img> by 
                Exads IT Solutions Pvt. Ltd</p>
        </div>
        <div className="ic">
            <a href="https://wa.me/+918999913424"><i className="fab fa-whatsapp"></i></a>
        </div>
    </div>
  )
};

export default Footer;
*/