import React from 'react'
import './Core.css';
import uu1 from './Images/aim.png';
import uu2 from './Images/mission.png';
import uu3 from './Images/vision.png';

const Core = () => {
  return (
    <div className='bc'>
        <div className='qq4'>
            <div className='cc1'>
                <img src={uu1} className='oo1'></img> <br/>
                <h4 className='ii1'>Aim</h4>
                <p className='ss1'>The aim of Nirvana Lounge and Restro is to provide a unique and unforgettable dining and lounge experience that exceeds our guest's expectations.</p>
            </div>
            <div className='cc1'>
                <img src={uu2} className='oo1'></img> <br/>
                <h5 className='ii1'>Mission</h5>
                <p className='ss1'>At Nirvana Lounge and Restro, we strive to provide exceptional service, delicious food, and a comfortable atmosphere for our guests.</p>
            </div>
            <div className='cc1'>
                <img src={uu3} className='oo1'></img> <br/>
                <h6 className='ii1'>Vision</h6>
                <p className='ss1'>Our vision is to be the destination of choice for those seeking an elevated dining and lounge experience. We want to be recognized as a leader in the industry for our exceptional food, service, and ambiance.</p>
            </div>
        </div>
    </div>
  )
};

export default Core;
