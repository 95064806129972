import React from 'react'
import './Dine.css';
import j1 from './Images/chicken-biryani.jpg';
import j2 from './Images/veg biryani.jpg';

import D1 from './Images/d1.jpg';
import D2 from './Images/d2.jpg';

const Dine = () => {
  return (
    <div className='cj'>
        <div className='ha'>
            <div className='sd'>
                <h5 className='qw'>Dine</h5>
                <p className='ft'>Our restaurant offers a variety of dishes that are prepared using fresh and high-quality ingredients. Our chefs have honed their skills in creating dishes that are not only visually appealing but also delicious  and flavorful. We also maintain a clean, cozy, and elegant atmosphere, which is perfect for intimate dining or celebrating special occasions with family and friends.</p>
                 <a className='mobsms' href='tel:8999913424'><button className='slide-button3'>Book your table now</button></a>
            </div>
            <div className='r1'>
                <div>
                     <img src={D1}  className='z1'></img>
                </div>
                <div>
                     <img src={D2}  className='z2'></img>
                </div>
            </div>
        </div>
    </div>
  )
};

export default Dine;
