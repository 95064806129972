import React from 'react'
import './Banner.css';
import yh from './Images/mc.png';

const Banner = () => {
  return (
    <div className='ssdd'>
        <div>
            <img src={yh} className='sw'></img>
        </div>
        <div>
            <h3 className='bn'>Menu Card</h3>
        </div>
    </div>
  )
};

export default Banner;
