import React from 'react'
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Menu from './Pages/Menu';
import Gallery from './Pages/Gallery';
import ContactUs from './Pages/ContactUs';
import Header from './Components/Header';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render (
    <Router>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contactus" element={<ContactUs />} />
        </Routes>
    </Router>
  );

  reportWebVitals();


