import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import React, { Component } from "react";
import Slider from "react-slick";


import k1 from './Images/stars.png';

import './Reviews.css';

export default class CardSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 5000,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
          responsive: [
                {
                  breakpoint: 400,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                  }
                }]

    };
    return (
      <div className='ju'>
        <Slider {...settings}>

             <div className="hv11">
                
                 <div className='ww11'>
                    <br/>
                     <h3 className='aa11'>Apurva Barge</h3>
                      <center>  
                        
                       <br/>
                          <br/>
                        ⭐⭐⭐⭐⭐ 
                         <br/>
                          <br/>
                        
                         </center>   
                     <p className='pp11'>I just loved this place... amazing area... peaceful.. perfect for dinner</p>
                 </div>
             </div>


                 <div className="hv11">
                
                 <div className='ww11'>
                    <br/>
                     <h3 className='aa11'>Pranav Chavan</h3>
                      <center>      
                       <br/>
                          <br/>
                        ⭐⭐⭐⭐⭐ 
                         <br/>
                          <br/>
                         </center>
                     <p className='pp11'>This is a restaurant with a calm surroundings. Suitable for family as well as friends and the food quality is also great here</p>
                 </div>
             </div>


                 <div className="hv11">
                
                 <div className='ww11'>
                    <br/>
                     <h3 className='aa11'>Shashikant Kadukar</h3>
                      <center>        
                       <br/>
                          <br/>
                        ⭐⭐⭐⭐⭐ 
                         <br/>
                          <br/>
                         </center>
                     <p className='pp11'>Great ambiance..Tasty <br></br> Food..</p>
                 </div>
             </div>


                 <div className="hv11">
                
                 <div className='ww11'>
                    <br/>
                     <h3 className='aa11'>Shivtej Saswade</h3>
                      <center>       
                       <br/>
                          <br/>
                        ⭐⭐⭐⭐⭐ 
                         <br/>
                          <br/>
                         </center>
                     <p className='pp11'>Usually visit at dinner time to enjoy ambience Liquor available with tasty food Cooperative staff and excellent atmosphere Tent like compartment</p>
                 </div>
             </div>


                 <div className="hv11">
                
                 <div className='ww11'><br/>
                     <h3 className='aa11'>Rupesh Tikone</h3>
                      <center>        
                       <br/>
                          <br/>
                        ⭐⭐⭐⭐⭐ 
                         <br/>
                          <br/>
                        </center>
                     <p className='pp11'>It was excellent experience 
                Fast Service, customer care
                food very tasty  but rate are 
                some high</p>
                 </div>
             </div>



                 <div className="hv11">
                
                 <div className='ww11'><br/>
                     <h3 className='aa11'>Mayur Kapre</h3>
                      <center>      
                       <br/>
                          <br/>
                        ⭐⭐⭐⭐⭐ 
                         <br/>
                          <br/>
                        </center>
                     <p className='pp11'>Very Good food Quality and service, 
                 thank you for Delicious cack</p>
                 </div>
             </div>



        </Slider>
      </div>
    );
  }
}

