import React from 'react'
import './Dine.css';
import y1 from './Images/d9.jpg';
import y2 from './Images/d10.jpg';

const DjNight = () => {
  return (
    <div className='cj'>
        <div className='ha'>
            <div className='r1'>
                <div>
                    <img src={y1} className='z1'></img>
                </div>
                <div>
                    <img src={y2} className='z2'></img>
                </div>
            </div>
            <div className='sd'>
                <h1 className='qw'>Dj Night</h1>
                <p className='ft'>Are you looking for a night full of beats, rhythm, and energy? Then, head over to our lounge and restaurant for our upcoming DJ night event! We have the best DJs in town, that will make you groove to their funky tunes and beats. Come and experience the ultimate party vibe at our lounge and restaurant. Book your tables in advance by visiting our website or by calling us.</p>
                <a className='mobsms' href='tel:8999913424'><button className='slide-button3'>Book your table now</button></a>
            </div>
        </div>
    </div>
  )
};

export default DjNight;
