import React from 'react'
import im from './Images/Nightlife.jpg';
import './Venue.css';
import { NavLink } from 'react-router-dom';

const Venue = () => {
  return (
    <div className='sec'>
         <div className='rf'>
             <div>
                 <img src={im} className='fii'></img>
             </div>
             <div>
                 <h3 className='part'>The Venue</h3>
                 <p className='hd'>Welcome to Nirvana lounge and restaurant!</p>
                 <br/>
                 <p className='pa'>Whether you come for a dinner date, a business 
                         meeting or a night out with friends, our lounge  and restaurant offers 
                         a unique and unforgettable experience. Join us for an unparalleled 
                         dining experience.</p>
                  <NavLink to="/aboutus"><button className='slide-button1'>About Us</button></NavLink>
             </div>
         </div>
    </div>
  )
};

export default Venue;
