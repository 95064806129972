import React from 'react'
import './Timing.css';

const Timing = () => {
  return (
    <div className='tt'><br/><br/><br/><br/><br/><br/>
        <div className='lc'>
             <div className='kn1'>
                 <h1 className='ff1'>Happy Hours</h1>
                 <p className='jq1'>11am - 9pm</p>
                 <p className='jq2'>10% Discount on Food and Drinks</p>
             </div>
             <div className='kn2'>
                 <h2 className='ff2'>Special Offer For Ladies</h2>
                 <p className='jq3'>Wednesday Night</p>
                 <p className='jq4'>FREE 3 Shots Tequila/Vodka and Hookah</p>
             </div>
        </div>
    </div>
  )
};

export default Timing;
