import React from 'react'
import './Image5.css';
import yu5 from './Images/img10.jpeg';
import rk5 from './Images/img5.jpeg';
import hc5 from './Images/dj night.jpg';
import x5 from './Images/img15.jpeg';

const Image5 = () => {
  return (
    <div id='zn'>
        <div className='pz'>

      <div className='ot1'>
                <img src={rk5} className='ab1'></img>
            </div>

            <div className='ot1'>
                <img src={yu5} className='ab1'></img>
            </div>

     <div className='ot1'>
                <img src={hc5} className='ab1'></img>
            </div>

              <div className="ot1">
                <img src={x5} className="ab1"></img>
            </div>

        </div>

            </div>
    
  )
};

export default Image5;