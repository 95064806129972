import React from 'react'
import Video from '../Components/Video';
import Venue from '../Components/Venue';
import OurSpecials from '../Components/OurSpecials';
import CardSlider from '../Components/CardSlider';
import Dine from '../Components/Dine';
import PartyAndEvents from '../Components/PartyAndEvents';
import Sheesha from '../Components/Sheesha';
import Drinks from '../Components/Drinks';
import { useEffect } from 'react';

import CouplesFriendly from '../Components/CouplesFriendly';

import DjNight from '../Components/DjNight'; 
import Counter from '../Components/Counter';
import Customer from '../Components/Customer';
import Reviews from '../Components/Reviews';
import Footer from '../Components/Footer';

const Home = () => {
    useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
        <Video></Video>
        <Venue></Venue>
        <OurSpecials></OurSpecials>
        <CardSlider></CardSlider>
        <Dine></Dine>
         <Drinks></Drinks>
        <PartyAndEvents></PartyAndEvents>
         <CouplesFriendly></CouplesFriendly>
         <Sheesha></Sheesha>
        <DjNight></DjNight>
        <Counter></Counter>
        <Customer></Customer>
        <Reviews></Reviews>
        <Footer></Footer>
    </div>
  )
};

export default Home;
