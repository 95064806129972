import React from 'react'
import './Dine.css';
import D1 from './Images/d5.jpg';
import D2 from './Images/d6.jpg';

const PartyAndEvents = () => {
  return (
    <div className='cj'>
        <div className='ha'>
            <div className='sd'>
                <h1 className='qw'>Party And Events</h1>
                <p className='ft'>Are you looking for the ultimate party  experience? Look no further than our upcoming events! We've got everything you need to make your night unforgettable, from live music and dancing to delicious food and drink. Plus, we take care of all the details, so you can focus on having a great time. From decorations to catering, we've got you covered. Join us for an evening of laughter, fun, and memories that will last a lifetime. Book now and get ready to party like never before!</p>
                      <a className='mobsms' href='tel:8999913424'><button className='slide-button3'>Book your table now</button></a>
            </div>
            <div className='r1'>
                <div>
                    <img src={D1} className='z1'></img>
                </div>
                <div>
                    <img src={D2} className='z2'></img>
                </div>
            </div>
        </div>
    </div>
  )
};

export default PartyAndEvents;
